import type { Gig } from 'gig/types/gig'
import type { Params } from '@/types/params'
import type { CreatorObject } from '@/types/variant'

export default async (params: Params) => {
  const products = await useFetchProducts(params)

  let creatorObject: CreatorObject | null | undefined = null
  try {
    if (!products.results?.length) {
      throw new Error('Page not found')
    }

    creatorObject = products?.results[0].masterVariant.attributes.find(
      (item) => item.name === 'creator',
    )?.value as CreatorObject | undefined

    if (!creatorObject) {
      throw new Error('Page not found')
    }
  } catch (error: unknown) {
    throw createError({
      statusCode: 404,
      statusMessage: error instanceof Error ? error.message : 'Page not found',
      fatal: true,
    })
  }

  const product = products.results[0]

  const gig: Gig = {
    id: product.id,
    createdAt: product.createdAt,
    name: product.name['en-US'],
    slug: product.slug['en-US'],
    description: product?.description?.['en-US'],
    contentType: product.masterVariant.attributes.find(
      (item) => item.name === 'content_type',
    )?.value,
    contentIsAbout: product.masterVariant.attributes.find(
      (item) => item.name === 'content_is_about',
    )?.value,
    reviewRatingStatistics: product?.reviewRatingStatistics,
    assets: useAssets(product.masterVariant),
    creator: useCreator({
      ...creatorObject.obj.masterData.current,
      id: creatorObject.obj.id,
      createdAt: creatorObject.obj.createdAt,
    }),
    packages: usePackages(product),
    brandIds:
      (product.masterVariant?.attributes?.find(
        (item) => item.name === 'brand_assets',
      )?.value as string[] | undefined) || [],
    state: product.state?.obj?.key,
    type: product.productType?.key,
  }

  return gig
}
